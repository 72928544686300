* {
	margin: 0;
	font-family: sans-serif;
}

.app {
	width: 1170px;
	margin-left: auto;
	margin-right: auto;
	overflow-y: none;

	display: flex;
	flex-flow: column;
	height: 100%;
}
.app__body {
	overflow-y: none;
}
.app__body {
	display: flex;
	background-color: #f9f9f9;
}

body {
	overflow-y: scroll;
}
