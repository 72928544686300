.filterSummary {
	font-weight: 600;
	font-size: 1.12em;
	line-height: 1.25;
	text-align: center !important;
	text-transform: uppercase;
	border: 2px solid #ddd;
	background-color: #ddd;
	color: #222;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 10px;
	margin-left: 10px;
	margin-top: 10px;
}

.filterSummary_arrow {
	position: relative;
	bottom: -17px;
	left: 90px;
	width: 0;

	border-top: 15px solid #ddd;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
}

.filterSummary__label {
	font-size: 0.92857rem;
	color: #222;
	font-weight: 400;
}
