.header {
	padding-top: 15px;

	position: sticky;
	background-color: #fff;
	z-index: 100;
	top: 0;
	flex: 0 1 auto;
}

.header__logo {
	display: flex;
	padding-bottom: 10px;
	justify-content: space-between;
	box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
}

.header__left > img {
	height: 40px;
}

.header__settings {
	padding-top: 8px;
	font-size: 28px !important;
}
