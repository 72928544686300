.menuBar {
	flex: 1;
	box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
}

.menuBar__list {
	display: flex;
	padding-inline-start: 10px;
}

.menuBar__item {
	list-style: none;
	margin-right: 20px;

	padding-top: 14px;
	padding-bottom: 6px;
	padding-left: 15px;
	padding-right: 15px;
}

.menuBar__item:hover {
	background-color: #ebebeb;
}

.menuBar__item:visited {
	background-color: #ebebeb;
	color: black;
}

.menuBar__link {
	text-transform: uppercase;
	color: black;
	line-height: 1.5;
	vertical-align: middle;
	font-size: 14px;
}

.menuBar__item {
	text-decoration: none;
}

.active {
	border-bottom: 5px solid red;
}
