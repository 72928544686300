.codeSystemBody {
	margin-left: 240px;
	/* margin-top: 10px; */
	width: 100%;
	padding: 15px 20px;
	padding-top: 10px;
	height: 100%;
}

.codeSystemtItem__codeSystemBody {
	width: 100%;
	display: block;
	flex: 1;
}

.codeSystemBody__codeSystemEmpty {
	background: #ececec;

	color: #383838;
	display: block;
	text-align: center;
	padding-top: 5px;
	padding-bottom: 5px;
}

.codeSystemBody__codeSystemEmptyText {
	text-align: center;
	margin: auto;
	font-weight: 600;
	font-size: 1em;
	border: 1px solid #ececec;
}
