.pageRefSets {
	min-height: calc(100vh - 130px);
	display: flex;
	align-items: stretch;
	padding-top: 10px;
}

.pageRefSets__list {
	background-color: peachpuff;
	flex: 0.3;
}

.pageRefSets__detail {
	background-color: plum;
	flex: 0.7;
}
