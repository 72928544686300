.valueSetItem {
	border-top: 1px solid #f0f0f0;
	/* width: 100; */
	margin-bottom: 10px;

	/* padding: 5px; */
	display: flex;
	height: 60px;
	background-color: #fefefe;
}

.valueSetItem__title {
	font-weight: 600;
	font-size: 16px;
}

.valueSetItem__status {
	position: absolute;
	top: 8px;
	right: 10px;
}

.valueSetItem__left {
	position: relative;
	flex: 0.8;
	padding-left: 10px;
	padding-top: 5px;
}
.valueSetItem__right {
	position: relative;
	text-align: right;
	flex: 0.2;
	padding-right: 10px;
	padding-top: 5px;
}

.valueSetItem__date {
	position: absolute;
	bottom: 5px;
	right: 10px;
	font-size: 12px;
}

.valueSetItem__title {
	position: absolute;
	top: 5px;
	left: 10px;
	font-weight: 600;
	font-size: 16px;
}

.valueSetItem__url {
	position: absolute;
	top: 23px;
	left: 10px;
	font-size: 12px;
}

.valueSetItem__tag {
	position: absolute;
	bottom: 5px;
	left: 10px;
	font-size: 12px;
}

.valueSetItem__version {
	font-size: 10px;
	color: gray;
}

.status_green {
	border-left: green 5px solid;
}

.status_amber {
	border-left: orange 5px solid;
}

.status_grey {
	border-left: gray 5px solid;
}

.pill {
	/* background-color: tomato; */
	padding: 3px 8px;
	border: 2px black;
	border-radius: 8px;
	text-transform: uppercase;
	font-size: 12px;
}

.pill_green {
	background-color: green;
	color: white;
}

.pill_amber {
	background-color: orange;
}

.pill_gray {
	background-color: gray;
}

.pill_blue {
	background-color: rgb(72, 72, 155);
	color: mintcream;
	margin-right: 4px;
	font-size: 10px;
}
