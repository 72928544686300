.statusFilter {
	font-weight: 600;
	font-size: 1.12em;
	line-height: 1.25;
	border: 2px solid #ddd;
	color: #222;
	padding-left: 7px;
	padding-right: 7px;
	padding-top: 14px;
	margin-left: 10px;
	margin-top: 20px;
	padding-bottom: 10px;
}

.statusFilter__options {
	padding-top: 10px;
	padding-bottom: 10px;
}

.statusFilter__title {
	font-size: 14px;
	font-weight: bold;
}

.statusFilter__reset {
	font-weight: 300;
	font-size: 11px;
	float: right;
	text-transform: uppercase;
	line-height: 1.25;
	cursor: pointer;
}

.statusFilter__reset:hover {
	text-decoration: underline;
}

.statusFilter__label {
	display: block;
	padding-left: 10px;
}

.statusFilter__label > span {
	font-weight: 400;
	font-size: 13px;
	align-content: center;
	padding-left: 10px;
	text-transform: none;
}

/* .statusFilter__label > input {
	-webkit-clip-path: inset(50%);
}

.statusFilter_checkHolder {
	position: absolute;
	left: 0;
	margin-right: 0.5em;
	display: inline-block;
	width: 18px;
	height: 18px;
	border: 1px solid #ddd;
	margin: 0;
} */
