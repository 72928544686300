.valueSetBody {
	margin-left: 240px;
	/* margin-top: 10px; */
	width: 100%;
	padding: 15px 20px;
	padding-top: 0px;
	height: 100%;
}

.valueSetBody__list {
	background-color: teal;
}
.valueSetItem__valueSetBody {
	width: 100%;
	display: block;
	flex: 1;
}

.valueSetBody__valueSetItem {
	width: 100%;
	display: block;
	flex: 1;
}

.valueSetBody__valueSetEmpty {
	background: #ececec;

	color: #383838;
	/* display: block; */
	/* text-align: center; */
	padding-top: 5px;
	padding-bottom: 5px;
}

.valueSetBody__valueSetEmptyText {
	text-align: center;
	margin: auto;
	font-weight: 600;
	font-size: 1em;
	border: 1px solid #ececec;
}
