.valueSetPage {
	display: flex;

	align-items: center;
	position: sticky;
	z-index: 100;
	top: 0px;
	margin-top: -10px;
	background-color: yellow;
}

.valueSetPage__left {
	flex: 0.7;
}

.valueSetPage__right {
	flex: 0.3;
}

.valueSetPage__size {
	background-color: #ececec;
	padding-left: 15px;
	padding-right: 15px;
	/* line-height: 20px; */
}

.valueSetPage__page {
	color: #383838;
	background-color: #f5f5f5;
	border-color: #ececec;

	font-size: 1em;
	/* line-height: 20px; */
}

.valueSetPage__pageCurent {
	color: #383838;
	background-color: #b8b6b6;
	border-color: #ececec;

	font-size: 1em;
	/* line-height: 20px; */
}
